// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-about-jsx": () => import("./../../../src/templates/About/About.jsx" /* webpackChunkName: "component---src-templates-about-about-jsx" */),
  "component---src-templates-articles-list-articles-list-jsx": () => import("./../../../src/templates/ArticlesList/ArticlesList.jsx" /* webpackChunkName: "component---src-templates-articles-list-articles-list-jsx" */),
  "component---src-templates-categorized-list-categorized-list-jsx": () => import("./../../../src/templates/CategorizedList/CategorizedList.jsx" /* webpackChunkName: "component---src-templates-categorized-list-categorized-list-jsx" */),
  "component---src-templates-contact-contact-jsx": () => import("./../../../src/templates/Contact/Contact.jsx" /* webpackChunkName: "component---src-templates-contact-contact-jsx" */),
  "component---src-templates-landing-landing-jsx": () => import("./../../../src/templates/Landing/Landing.jsx" /* webpackChunkName: "component---src-templates-landing-landing-jsx" */),
  "component---src-templates-post-post-jsx": () => import("./../../../src/templates/post/post.jsx" /* webpackChunkName: "component---src-templates-post-post-jsx" */),
  "component---src-templates-tag-tag-jsx": () => import("./../../../src/templates/tag/tag.jsx" /* webpackChunkName: "component---src-templates-tag-tag-jsx" */),
  "component---src-templates-terms-terms-jsx": () => import("./../../../src/templates/Terms/Terms.jsx" /* webpackChunkName: "component---src-templates-terms-terms-jsx" */)
}

